<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Change Password</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <form @submit.prevent="changePassword">
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
          <label for="currentPassword" style="margin-top:5px;">Current Password</label>
          <input id="currentPassword" v-model="currentPassword" class="form-control" required type="password">
          <span v-if="errors.currentPassword" class="error">{{ errors.currentPassword }}</span>

        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
          <label for="newPassword" style="margin-top:5px;">New Password</label>
          <input id="newPassword" v-model="newPassword" class="form-control" required type="password">
          <span v-if="errors.newPassword" class="error">{{ errors.newPassword }}</span>
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
          <label for="confirmPassword" style="margin-top:5px;">Confirm Password</label>
          <input id="confirmPassword" v-model="confirmPassword" class="form-control" required type="password">
          <span v-if="errors.confirmPassword" class="error">{{ errors.confirmPassword }}</span>
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
          <label for="confirmPassword" style="margin-top:5px;"></label>
          <button class="btn btn-primary" type="submit">Change Password</button>
        </div>
      </form>
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
      </div>
    </div>
  </div>
</template>

<script>
import userAPI from '@/api/user'
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('home', ['menuItems']),
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      errors: {}
    };
  },
  methods: {
    ...mapActions('auth',['logoutUser']),
    async changePassword() {
      this.errors = {};
      if (this.currentPassword === '') {
        this.errors.currentPassword = 'Current Password is required';
      }
      if (this.newPassword === '') {
        this.errors.newPassword = 'New Password is required';
      }
      if (this.confirmPassword === '') {
        this.errors.confirmPassword = 'Please confirm your new password';
      } else if (this.newPassword !== this.confirmPassword) {
        this.errors.confirmPassword = 'Passwords do not match';
      }
      if (Object.keys(this.errors).length === 0) {
        try{
          const request = {
            oldPassword: this.currentPassword,
            newPassword: this.newPassword
          };
        const response= await userAPI.changePassword(request);
        alert(response.data.message);
        if (response.data.success) {
            this.logout();
        }
      } catch (error) {
        alert(error.response.data.message);
        console.error('Error while changing password:', error);
      }
      }
    },
    logout(){
      this.logoutUser();
      this.$router.push('/')
    }
  },
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
