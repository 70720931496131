<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Store Owners</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button @click="openModal(null,'add')" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>
        <!-- Vendor Table using DataTables -->
        <table ref="vendorDataTable" class="table mt-3">
          <thead>
          <tr>
            <th>User ID</th>
            <th>Full Name</th>
            <th>Email Id</th>
            <th>Aadhaar No. & <br/> Address</th>
            <th>Mobile Number</th>
            <th>Aadhaar Pic</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="vendor in vendors" :key="vendor.userId">
            <td>{{ vendor.userId }}</td>
            <td>{{ vendor.fullName }}</td>
            <td>{{ vendor.emailId }}</td>
            <td>{{ vendor.aadhaarNumber }} <br/> {{ vendor.addressLine }}</td>
            <td>{{ vendor.primaryMobileNumber }} <br/> {{ vendor.secondaryMobileNumber }}</td>
            <td><img v-if="vendor.aadhaarPicUrl" :src="vendor.aadhaarPicUrl" alt="" width="100" height="70"></td>
            <td>
              <p v-if="vendor.active" class="ongoing" style="text-align:center">{{ vendor.active }}</p>
              <p v-else class="progress1" style="text-align:center">false</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openModal(vendor,'edit')">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1">
                </a>
                <a href="#" @click="openModal(vendor,'block')" v-if="vendor.active">
                  <img :src="require('@/assets/images/block.png')" v-b-tooltip.hover title="Block" width="25" height="25" class="btn-tbl-black">
                </a>
                <a href="#" @click="openModal(vendor,'unblock')" v-else>
                  <img :src="require('@/assets/images/unlocked.png')" v-b-tooltip.hover title="UnBlock" width="25" height="25" class="btn-tbl-black">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>


        <!--Customize modal-->
        <div id="modal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  <span v-if="action==='edit'">
                    Edit Store Owner
                  </span>
                  <span v-if="action==='add'">
                    Add Store Owner
                  </span>
                  <span v-if="action==='block'">
                    Block Store Owner
                  </span>
                  <span v-if="action==='unblock'">
                    Unblock Store Owner
                  </span>
                </h5>
                <button type="button" class="btn btn-danger" @click="closeModal">
                  <span>&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div v-if="action==='edit' || action==='add'">
                  <div class="row mb-1">
                    <label class="col-sm-4 col-form-label"> Full Name </label>
                    <div class="col-sm-8">
                      <input type="text" v-model="fullName" class="form-control" placeholder="Full Name">
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-sm-4 col-form-label"> Email Id </label>
                    <div class="col-sm-8">
                      <input type="text" v-model="emailId" class="form-control" placeholder="Mail">
                    </div>
                  </div>

                  <div class="row mb-1">
                    <label class="col-sm-4 col-form-label"> Aadhaar Number </label>
                    <div class="col-sm-8">
                      <input type="text" v-model="aadhaarNumber" class="form-control" placeholder="Aadhaar">
                    </div>
                  </div>
                  <div v-if="aadhaarPicUrl" class="row mb-1">
                    <label class="col-sm-2 col-form-label">Aadhaar Pic </label>
                    <div class="col-sm-2">
                      <img :src="aadhaarPicUrl" alt="" width="70" height="70">
                    </div>
                    <div class="col-sm-8">
                      <input type="file" @change="handleFileChange" class="form-control">
                    </div>
                  </div>
                  <div v-else class="row mb-1">
                    <label class="col-sm-4 col-form-label">Aadhaar Pic </label>
                    <div class="col-sm-8">
                      <input type="file" @change="handleFileChange" class="form-control">
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-sm-4 col-form-label"> Address Line </label>
                    <div class="col-sm-8">
                      <input type="text" v-model="addressLine" class="form-control" placeholder="Address">
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-sm-4 col-form-label"> Mobile Number </label>
                    <div class="col-sm-8">
                      <input type="text" v-model="mobileNumber" class="form-control" :maxlength="max"  placeholder="MobileNumber"/>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <label class="col-sm-4 col-form-label"> Alternative Mobile Number </label>
                    <div class="col-sm-8">
                      <input type="text" v-model="alternativeMobileNumber" :maxlength="max" class="form-control"
                             placeholder="Alternate Mobile Number">
                    </div>
                  </div>
                </div>
                <div v-if="action==='block' || action==='unblock'">
                  <span>
                    Are you sure you want to {{ action === 'block' ? 'block' : 'unblock' }} {{ fullName }}?
                  </span>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                <button type="button" class="btn btn-primary" @click="modalSubmit">
                  <span v-if="action==='edit'">
                    Update
                  </span>
                  <span v-if="action==='add'">
                    Add
                  </span>
                  <span v-if="action==='block'">
                    Block
                  </span>
                  <span v-if="action==='unblock'">
                    Unblock
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userAPI from '@/api/user';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
import countryAPI from "@/api/location/country";
import stateAPI from "@/api/location/state";
import cityAPI from "@/api/location/cities";

export default {
  data() {
    return {
      countries: [],
      states: [],
      cities: [],
      areas: [],
      max: 10,
      text: '',
      file: '',
      editingObject: null,
      fullName: '',
      emailId: '',
      aadhaarNumber: '',
      addressLine: '',
      mobileNumber: '',
      alternativeMobileNumber: '',
      aadhaarPicUrl: '',
      vendors: [],
      action: '',
      selectedCountry: 0,
      selectedState: 0,
      selectedCity: 0,
      selectedArea: 0,

    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    openModal(vendor, actionType) {
      this.action = actionType
      if (vendor !== null) {
        this.editingObject = vendor;
        this.fullName = vendor.fullName;
        this.emailId = vendor.emailId;
        this.aadhaarNumber = vendor.aadhaarNumber;
        this.addressLine = vendor.addressLine;
        this.mobileNumber = vendor.primaryMobileNumber;
        this.alternativeMobileNumber = vendor.secondaryMobileNumber;
        this.aadhaarPicUrl = vendor.aadhaarPicUrl;
        this.selectedCountry = vendor.countryId;
        this.selectedState = vendor.stateId;
        this.fetchCities(vendor.countryId, vendor.stateId);
        this.selectedCity = vendor.cityId;
        this.fetchAreas(vendor.countryId, vendor.stateId,vendor.cityId);
        this.selectedArea = vendor.areaId;
      } else {
        this.fullName = '';
        this.emailId = '';
        this.aadhaarNumber = '';
        this.addressLine = '';
        this.mobileNumber = '';
        this.alternativeMobileNumber = '';
        this.aadhaarPicUrl = ''
        this.selectedCountry = '1';
        this.selectedState = '';
        this.selectedCity = '';
        this.selectedArea = '';
      }
      const modal = document.getElementById('modal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeModal() {
      const modal = document.getElementById('modal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
      this.editingObject = null;
      this.fullName = '';
      this.emailId = '';
      this.aadhaarNumber = '';
      this.addressLine = '';
      this.mobileNumber = '';
      this.selectedCountry = '';
      this.selectedState = '';
      this.selectedCity = '';
      this.selectedArea = '';
      this.alternativeMobileNumber = '';
    },
    async modalSubmit() {
      if (this.action === 'edit' || this.action === 'add') {
        if (this.fullName.trim() !== '' && this.emailId.trim() !== '') {
          let response = '';
          try {
            if (this.file) {
              if (this.file.size > 100 * 1024 * 1024) {
                alert('File size should be less than 100MB');
              }
              if (!['image/jpeg', 'image/png', 'application/pdf'].includes(this.file.type)) {
                alert('File type not supported. Supported types: JPEG, PNG, PDF');
              }
            }
            if (this.action === 'edit') {
              response = await userAPI.updateVendor({
                fullName: this.fullName,
                emailId: this.emailId,
                aadhaarNumber: this.aadhaarNumber,
                addressLine: this.addressLine,
                primaryMobileNumber: this.mobileNumber,
                secondaryMobileNumber: this.alternativeMobileNumber,
                aadhaarPicUrl: this.aadhaarPicUrl,
                roleId: 2,
                aadhaarPic: this.file ? this.file : null,
              }, this.editingObject.userId)
              alert(response.data.message);
              if (response.data.success) {
                await this.fetchVendors();
                this.closeModal();
              }
            } else {
              response = await userAPI.addVendor({
                fullName: this.fullName,
                emailId: this.emailId,
                aadhaarNumber: this.aadhaarNumber,
                addressLine: this.addressLine,
                primaryMobileNumber: this.mobileNumber,
                secondaryMobileNumber: this.alternativeMobileNumber,
                aadhaarPicUrl: this.aadhaarPicUrl,
                aadhaarPic: this.file ? this.file : null,
                roleId: 2
              })
              alert(response.data.message)
              if (response.data.success) {
                await this.fetchVendors();
                this.closeModal();
              }
            }
          } catch (error) {
            console.error("Error updating vendor:", error);
            alert(error.response.data.message);
          }
        } else {
          alert('Type both email ID and full Name')
        }
      } else {
        try {
          const response = await userAPI.patchVendors(this.editingObject.userId)
          alert(response.data.message)
          if (response.data.success) {
            await this.fetchVendors();
            this.closeModal();
          }
        } catch (error) {
          console.error("error blocking vendor:", error)
        }
      }

    },
    async fetchVendors() {
      try {
        const response = await userAPI.getVendors();
        this.vendors = response.data;
        this.initializeDataTable();

      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    },
    async fetchCountries() {
      try {
        const response = await countryAPI.getCountries();
        this.countries = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching countries:', error);
        alert(error.response.data.message);
      }
    },
    async fetchStates(country) {
      try {
        const response = await stateAPI.getStates(country);
        this.states = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching states:', error);
        alert(error.response.data.message);
      }
    },
    async fetchCities(country, state) {
      try {
        const response = await cityAPI.getCities(country, state);
        this.cities = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching cities:', error);
        alert(error.response.data.message);
      }
    },
    async fetchAreas(country, state, city) {
      try {
        const response = await cityAPI.getArea(country, state, city);
        this.areas = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching Areas:', error);
        alert(error.response.data.message);
      }
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.vendorDataTable)) {
        $(this.$refs.vendorDataTable).DataTable().destroy();
      }
      const exportColumns = [0,1,2,3,4,5,6]
      this.$nextTick(() => {
        $(this.$refs.vendorDataTable).DataTable({
          mark: true,
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: 'vendorList',
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'csv',
              title: 'vendorList',
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'excel',
              title: 'vendorList',
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'pdf',
              title: 'vendorList',
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'print',
              title: 'vendorList',
              exportOptions: {
                columns: exportColumns
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchVendors();
    // this.fetchCountries();
    // this.selectedState = '1';
    // this.fetchStates(1);
  },
};
</script>
