<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Holidays</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button
                @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>

        <table ref="storesTable" class="table mt-3">
          <thead>
          <tr>
            <th>Holiday Name</th>
            <th>Holiday Date</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="holiday in holidays" :key="holiday.holidayId">
            <td>{{ holiday.holidayReason }}</td>
            <td>{{ holiday.holidayDate }}</td>
            <td>
              <p v-if="holiday.active" class="ongoing" style="text-align:center">{{ holiday.active }}</p>
              <p v-else class="progress1" style="text-align:center">false</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(holiday,true)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div id="dataModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Holiday</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Holiday Name </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedHoliday.holidayReason" class="form-control"
                           placeholder="Holiday Reason">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Holiday Date </label>
                  <div class="col-sm-8">
                    <input type="date" v-model="updatedHoliday.holidayDate" class="form-control control-fr" id="startDate">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" v-if="editFlag"> Active </label>
                  <div class="col-sm-8">
                    <select v-if="editFlag" v-model="updatedHoliday.active" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="updateHoliday">Save Changes</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import storeAPI from '@/api/store/store.js';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      holidays: [],
      updatedHoliday: "",
      editFlag: "",
    };
  },
  methods: {
    openEditModal(holiday, flag) {
      this.editFlag = flag;
      const modal = document.getElementById('dataModal');
      if (this.editFlag) {
        this.updatedHoliday = holiday;
        if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
        }
      } else {

        this.updatedHoliday = {
          holidayId: 0,
          holidayDate: null,
          holidayReason: null,
          active: true
        };
        if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
        }
      }
    },
    closeEditModal() {
      this.updatedHoliday = "";
      const modal = document.getElementById('dataModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchHolidays() {
      try {
        const response = await storeAPI.fetchHolidays();
        this.holidays = response.data;
        this.initializeDataTable();
      } catch (error) {
        alert(error.response.data.message);
      }
    },
    async updateHoliday() {
      const updateHolidays = {
        holidayDate: this.updatedHoliday.holidayDate,
        holidayReason: this.updatedHoliday.holidayReason,
        active: this.updatedHoliday.active,
      };
      try {
        let response = null;
        if (this.editFlag) {
          response = await storeAPI.updateHoliday(this.updatedHoliday.holidayId, updateHolidays);
        } else {
          response = await storeAPI.addHoliday(updateHolidays);
        }
        alert(response.data.message);
        if (response.data.success) {
          await this.fetchHolidays();
          this.closeEditModal();
        }
      } catch (error) {
        alert(error.response.data.message);
      }
    },
    getExportTitle() {
        return 'Holidays';
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.storesTable)) {
        $(this.$refs.storesTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2];

      this.$nextTick(() => {
        $(this.$refs.storesTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
   this.fetchHolidays();
  },
};
</script>
