import axios from "axios";
import { BASE_URL,getAuthHeader } from "../apiUtils";

const otpAPI = {
    get(){
        return axios.get(`${BASE_URL}/other-configs/get-otp-config`,{headers: getAuthHeader()});

    },
    update(data){
        return axios.post(`${BASE_URL}/other-configs/update-otp-config`,data,{headers:getAuthHeader()});
    }
}

export default otpAPI