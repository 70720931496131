import axios from 'axios';
import {BASE_URL, getAuthHeader} from '../apiUtils';

const storeAPI = {
  getStores(path, limitData) {
    if (limitData) {
      path += '&show_max_data=false';
    }
    return axios.get(`${BASE_URL}/stores/get-stores?`+path, { headers: getAuthHeader() });
  },
  
  updateStores(id,updatedData) {
    return axios.put(`${BASE_URL}/stores/update-store/`+id, updatedData, { headers: getAuthHeader() });
  },
  addStores(updatedData) {
    return axios.post(`${BASE_URL}/stores/add-store`, updatedData, { headers: getAuthHeader() });
  },

  fetchUsers() {
    return axios.get(`${BASE_URL}/users/get-admins-users`,  { headers: getAuthHeader() });
  },

  fetchVendors(path) {
    return axios.get(`${BASE_URL}/users/get-vendors-by-location?${path}`,  { headers: getAuthHeader() });
  },

  fetchStoreMappings(storeId) {
    return axios.get(`${BASE_URL}/services/store-mapping?store_id=${storeId}`, { headers: getAuthHeader() });

  },

  mapStoreToService(storeId,serviceId) {
    return axios.get(`${BASE_URL}/services/map-store-to-service/store/${storeId}/service/${serviceId}`, { headers: getAuthHeader() });

  },

  fetchHolidays() {
    return axios.get(`${BASE_URL}/stores/get-holidays`,  { headers: getAuthHeader() });
  },

  addHoliday(updatedData) {
    return axios.post(`${BASE_URL}/stores/add-holiday`, updatedData, { headers: getAuthHeader() });
  },

  updateHoliday(id,updatedData) {
    return axios.put(`${BASE_URL}/stores/update-holiday/${id}`, updatedData, { headers: getAuthHeader() });
  },

  fetchStoreTimings() {
    return axios.get(`${BASE_URL}/stores/get-store-timings`,  { headers: getAuthHeader() });
  },

  updateStoreTimings(data) {
    return axios.post(`${BASE_URL}/stores/update-store-timings`,  data,{ headers: getAuthHeader() });
  },


};

export default storeAPI;
