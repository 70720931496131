import axios from 'axios';

const BASE_URL = 'https://www.pleatsanddrapes.com:8002/pleats-drapes-admin';

const authAPI = {
  async login(username, password) {
    try {
      const response = await axios.post(`${BASE_URL}/authenticate/login`, {
        emailId: username,
        password: password,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};

export default authAPI;
