import axios from "axios";
import { BASE_URL, getAuthHeader } from  '../apiUtils';

const notificationAPI = {
    getnotifications(){
        return axios.get(`${BASE_URL}/other-configs/get-push-notifications`,{headers: getAuthHeader()});
    },
    postNotification(data){
        return axios.post(`${BASE_URL}/other-configs/send-push-notification`,data,{headers:getAuthHeader()});
    },
    
}

export default notificationAPI