<template>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
      <div class="d-flex">  
        <div class="dashboard-title ps-2 ps-md-0">
          <h4>Page coming soon</h4>
          <img style="justify-content: center;align-items: center;" :src="require('@/assets/images/coming_soon.png')">
        </div>
      </div> 
    </div>
</template>
<script>
    export default{

    }
</script>
<style scoped>
</style>