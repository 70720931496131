<template>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
      <div class="d-flex">  
        <div class="dashboard-title ps-2 ps-md-0">
          <h4>Banners List</h4>
        </div>
      </div> 
      <div class="row pt-4 pt-xxl-5" style="position:relative">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <button @click="openEditModal" class="btn btn-success" style="position:absolute;right:30px;">
            Add
          </button>
          <!-- City Table using DataTables -->
          <table ref="bannerDataTable" class="table mt-3">
            <thead>
                <tr>
                  <th>Banner ID</th>
                  <th>Banner Image</th>
                  <th>Screen Location</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>   
            </thead>
            <tbody>
                <tr v-for="banner  in banners" :key="banner.bannerId">
                  <td>{{ banner.bannerId }}</td>
                  <td>
                    <a v-if="banner.bannerUrl.includes('youtube')" :href="banner.bannerUrl" target="_blank"> <img :src="require('@/assets/images/youtube.png')" alt="" width="100" height="50"> </a>
                    <img v-else :src="banner.bannerUrl" alt="" width="100" height="70">
                  </td>
                  <td>{{ banner.screenLocation }}</td>
                  <td>
                    <p v-if="banner.active" class="ongoing" style="text-align:center">{{ banner.active }}</p>
                    <p v-else class="progress1" style="text-align:center">{{ banner.active }}</p>
                  </td>
                  <td>
                    <a href="#" @click="openInactiveModal(banner)">
                      <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1"
                           alt="">
                    </a>
                    <a href="#" @click="openDeleteModal(banner)">
                      <img :src="require('@/assets/images/delete.png')" width="25" height="25" class="btn-tbl-red"
                           alt="">
                    </a>
                  </td>
                </tr>
            </tbody>
          </table>  
          
          <!-- Edit Modal -->
          <div id="editDetailsModal" class="modal fade">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- Edit Header -->
                <div class="modal-header">
                  <h5 class="modal-title">Add Banners</h5>
                  <button type="button" class="btn btn-danger" @click="closeEditModal">
                    <span>&times;</span>
                  </button>
                </div>
                <!-- Edit Body -->
                <div class="modal-body">
                      <label>Screen Location</label>
                      <select v-model="screenLocation" class="form-control">
                        <option value="dashboard">Dashboard</option>
                        <option value="measurement_chart">Measurement Chart</option>
                      </select>
                  <br>
                      <label>Browse Field</label>
                      <input type="file" @change="handleFileChange" class="form-control">
                  <br>
                      <label>YouTube Link</label>
                      <input type="text" v-model="youtubeLink" class="form-control" placeholder="Enter YouTube link">
                </div>
                <!-- Edit Footer -->
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                  <button type="button" class="btn btn-primary" @click="updateDetails">Save Changes</button>
                </div>
              </div>
            </div>
          </div>  
          <div id="deleteModal" class="modal fade">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Delete Banner</h5>
                  <button type="button" class="btn btn-danger" @click="closeDeleteModal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>
                    Are you sure you want to delete?
                  </span>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="closeDeleteModal">Close</button>
                  <button type="button" class="btn btn-primary" @click="deleteBanner">Delete</button>
                </div>
              </div>
            </div>
          </div>

          <div id="inactiveModal" class="modal fade">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Inactive Banner</h5>
                  <button type="button" class="btn btn-danger" @click="closeInactiveModal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>
                    Are you sure you want to update the status of this banner ?
                  </span>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="closeInactiveModal">Close</button>
                  <button type="button" class="btn btn-primary" @click="inactiveBanner">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
</template>

<script>

import bannerAPI from '@/api/other-configs/banner.js'
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
    data() {
        return {
            banners : [],
            youtubeLink: '',
            screenLocation: 'dashboard',
            file:'',
            selectedBanner:null,
        }
    },
    methods: {
        handleFileChange(event) {
          this.file = event.target.files[0];
        },
        openEditModal() {
          const modal = document.getElementById('editDetailsModal');
          if (modal) {
              modal.classList.add('show');
              modal.style.display = 'block';
          }
        },
        closeEditModal() {
            this.youtubeLink = null;
            this.screenLocation = '';
            this.file = '';
            const modal = document.getElementById('editDetailsModal');
            if (modal) {
                modal.classList.remove('show');
                modal.classList.remove('hide');
                modal.style.display = 'none';
                document.body.classList.remove('modal-open');
                const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (modalBackdrop) {
                    modalBackdrop.parentNode.removeChild(modalBackdrop);
                }
            }
        },
        openDeleteModal(banner){
          this.selectedBanner=banner;
          const modal = document.getElementById('deleteModal');
          if (modal) {
              modal.classList.add('show');
              modal.style.display = 'block';
          }
        },
        closeDeleteModal(){
          this.selectedBanner=null;
          const modal = document.getElementById('deleteModal');
            if (modal) {
                modal.classList.remove('show');
                modal.classList.remove('hide');
                modal.style.display = 'none';
                document.body.classList.remove('modal-open');
                const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (modalBackdrop) {
                    modalBackdrop.parentNode.removeChild(modalBackdrop);
                }
            }
        },
      openInactiveModal(banner) {
        this.selectedBanner = banner;
        const modal = document.getElementById('inactiveModal');
        if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
        }
      },
      closeInactiveModal() {
        this.selectedBanner = null;
        const modal = document.getElementById('inactiveModal');
        if (modal) {
          modal.classList.remove('show');
          modal.classList.remove('hide');
          modal.style.display = 'none';
          document.body.classList.remove('modal-open');
          const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
          if (modalBackdrop) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
          }
        }
      },
        async fetchBanners(){
            try{
                const response =  await bannerAPI.getBanners();
                this.banners = response.data 
                this.initializeDataTable();
            } catch (error) {
                console.error("Error fetching countries:",error);
            }
        },
        async updateDetails() {
            let formData = new FormData();
            formData.append('screenLocation', this.screenLocation);

          if (this.youtubeLink != null && this.youtubeLink.trim() !== '') {
                formData.append('youtubeLink', this.youtubeLink);
            } else {
                formData.append('multipartFile', this.file);
            }

            try {
                const response = await bannerAPI.postBanner(formData);
                 this.closeEditModal();
              alert(response.data.message);
                await this.fetchBanners();
            } catch (error) {
                alert(error.response.data.message)
            }
        },
        async deleteBanner(){
          try{
            const response = await bannerAPI.deleteBanner(this.selectedBanner.bannerId);
            if(response.data.success){
              alert(response.data.message);
            }
            this.closeDeleteModal();
            await this.fetchBanners();
          }catch(error){
            console.error('Error deleting banner',error)
          }
        },
      async inactiveBanner() {
        try {
          const response = await bannerAPI.updateBanner(this.selectedBanner.bannerId, !this.selectedBanner.active);
          if (response.data.success) {
            alert(response.data.message);
            this.closeInactiveModal();
            await this.fetchBanners();
          }
        } catch (error) {
          console.error('Error deleting banner', error)
        }
      },
        initializeDataTable() {
            // Destroy existing DataTable
            if ($.fn.DataTable.isDataTable(this.$refs.bannerDataTable)) {
            $(this.$refs.bannerDataTable).DataTable().destroy();
            }
            // Initialize DataTable when the component is mounted or when states change
            this.$nextTick(() => {
                $(this.$refs.bannerDataTable).DataTable({
                    dom: 'Bfrtip',
                    buttons: [
                    {
                        extend: 'copy',
                        title: 'bannerList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'csv',
                        title: 'bannerList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'excel',
                        title: 'bannerList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'pdf',
                        title: 'bannerList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    {
                        extend: 'print',
                        title: 'bannerList',
                        exportOptions: {
                        columns: [0, 1, 2] // Exclude the 3rd column (0-based index)
                        },
                    },
                    ],
                    // Add any DataTables options you need here
                });
            });
        },


    },
    created() {
      this.fetchBanners();
    },
}
</script>