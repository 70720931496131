import axios from 'axios';
import store from '@/store';

export const BASE_URL = 'https://www.pleatsanddrapes.com:8002/pleats-drapes-admin';

export function getAuthHeader() {
  if (store.state.auth.isAuthenticated) {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
  }
 return {};
}

export async function refreshToken() {
  const token = localStorage.getItem('jwtToken');
  const emailId = store.state.auth.user.email; 
  try {
    const response = await axios.post(
      `${BASE_URL}/authenticate/refreshToken`,
      { emailId, token }, 
      { headers: getAuthHeader() }
    );
    localStorage.setItem('jwtToken', response.data.token);
    return response.data.token;
  } catch (error) {
    throw error.response.data;
  }
}
