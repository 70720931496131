import axios from 'axios';
import { BASE_URL } from '../apiUtils';
import { getAuthHeader} from '../apiUtils'; 
const stateAPI = {
  getStates(countryId) {
    return axios.get(`${BASE_URL}/location/get-states?country_id=${countryId}`, { headers: getAuthHeader() });
  },
  
  updateState(updatedData) {
    return axios.post(`${BASE_URL}/location/update-state`, updatedData, { headers: getAuthHeader() });
  },
};

export default stateAPI;
