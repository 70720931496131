<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Store Timings</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">

      <div class="row mb-1">
        <label class="col-sm-4 col-form-label"> Select Start Time </label>
        <div class="col-sm-4">
          <input type="time" id="timepicker" class="form-control" v-model="selectedTime">
        </div>
      </div>

      <div class="row mb-1">
        <label class="col-sm-4 col-form-label"> Select End Time </label>
        <div class="col-sm-4">
          <input type="time" id="timepicker" class="form-control" v-model="endTime">
        </div>
      </div>

      <div class="row mb-1">
        <label class="col-sm-4 col-form-label"> Select WeekOffs: </label>
        <div class="col-sm-8">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="Monday" id="Monday">
            <label class="form-check-label" for="Monday">Monday</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="Tuesday" id="Tuesday">
            <label class="form-check-label" for="Tuesday">Tuesday</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="Wednesday" id="Wednesday">
            <label class="form-check-label" for="Wednesday">Wednesday</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="Thursday" id="Thursday">
            <label class="form-check-label" for="Thursday">Thursday</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="Friday" id="Friday">
            <label class="form-check-label" for="Friday">Friday</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="Saturday" id="Saturday">
            <label class="form-check-label" for="Saturday">Saturday</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="Sunday" id="Sunday">
            <label class="form-check-label" for="Sunday">Sunday</label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10 mt-2 d-flex justify-content-center">
      <button @click="save" class="btn btn-success">
        Save
      </button>
    </div>

  </div>
</template>

<script>
import storeAPI from '@/api/store/store.js';

export default {
  data() {
    return {
      storeTimings: [],
      daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      selectedTime: '',
      endTime: '',
      weekOfDays: '',
    };
  },
  methods: {
    async fetchStoreTimings() {
      try {
        const response = await storeAPI.fetchStoreTimings();
        this.storeTimings = response.data;
        const checkboxes = document.querySelectorAll('.form-check-input');
        if (this.storeTimings.weekOffDays != null) {
          const week = this.daysOfWeek;
          this.storeTimings.weekOffDays.forEach((value, index) => {
            this.weekOfDays += week[value - 1] + ",";
            checkboxes.forEach(function (checkbox) {
              if (checkbox.value === week[value - 1]) {
                checkbox.checked = true;
              }
            });

          });
        }
        this.selectedTime = this.storeTimings.openTime;
        this.endTime = this.storeTimings.closeTime;
        this.weekOfDays = this.weekOfDays.slice(0, -1);
      } catch (error) {
        alert(error.response.data.message);
      }
    },
    async save() {
      try {
        const checkboxes = document.querySelectorAll('.form-check-input');
        let selectedWeekoffs = [];
        const dayIndices = {
          Monday: 1,
          Tuesday: 2,
          Wednesday: 3,
          Thursday: 4,
          Friday: 5,
          Saturday: 6,
          Sunday: 7
        };
        checkboxes.forEach(function (checkbox) {
          if (checkbox.checked) {
            selectedWeekoffs.push(dayIndices[checkbox.value]);
          }
        });
        const [hours, minutes] = this.selectedTime.split(':');
        const [endHours, endMinutes] = this.endTime.split(':');
        this.selectedTime = `${hours}:${minutes}:00`;
        this.endTime = `${endHours}:${endMinutes}:00`;
        const data = {
          weekOffDays: selectedWeekoffs,
          openTime: this.selectedTime,
          closeTime: this.endTime
        };
        const response = await storeAPI.updateStoreTimings(data);
        alert(response.data.message);
        await this.fetchStoreTimings();
      } catch (error) {
        alert(error.response.data.message);
      }
    },

  },
  created() {
    this.fetchStoreTimings();
  }
};
</script>
