import axios from "axios";
import { BASE_URL, getAuthHeader } from  '../apiUtils';

const taxesAPI = {
    get(){
        return axios.get(`${BASE_URL}/other-configs/get-taxes`,{headers: getAuthHeader()});
    },
    update(id,data){
        return axios.put(`${BASE_URL}/other-configs/update-tax/${id}`,data,{headers:getAuthHeader()})
    },
    post(data){
        return axios.post(`${BASE_URL}/other-configs/add-tax`,data,{headers: getAuthHeader()})
    }
}

export default taxesAPI