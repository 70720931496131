import axios from "axios";
import { BASE_URL, getAuthHeader } from  '../apiUtils';

const bannerAPI = {
    getBanners(){
        return axios.get(`${BASE_URL}/other-configs/get-banners`,{headers: getAuthHeader()});
    },
    updateBanner(id,data){
        return axios.put(`${BASE_URL}/other-configs/update-banner-status/${id}?status=${data}`, null , {headers: getAuthHeader()});
    },
    postBanner(data) {
        const headers = {
            ...getAuthHeader(),
            'Content-Type': 'multipart/form-data'
        };
        return axios.post(`${BASE_URL}/other-configs/add-banner`, data, { headers });
    },
    deleteBanner(id) {
        return axios.delete(`${BASE_URL}/other-configs/delete-banner/${id}`,{headers:getAuthHeader()})
    }
}

export default bannerAPI