<!-- App.vue --> 
<template> 
  <section id="dash-board">
    <div class="container-fluid px-0">
      <div class="row m-0 p-0">
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 px-0">
        <div class="pleats d-flex ms-4 mt-2">
          <img src="@/assets/images/ic_logo.png" class="img-fluid">
          <p>Pleats & Drapes</p>
        </div>
          <div>
            <p class="pleats d-flex ms-3"v-if="$route.path !== '/'" > {{getName() }}</p>
          </div>
 
          <nav class="navbar navbar-expand-lg mt-0 mt-lg-4">
            <div class="container-fluid px-0">
              <div class="text-end">
                <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
            <template v-if="$route.path !== '/'">
            <menuBar class="menu" /> 
            </template>
          </nav>
        </div>  
          <router-view></router-view>
      </div>  
    </div>    
  </section>
</template>

<script setup>
import menuBar from './components/SideBar.vue';
import bootstrapBundle from '@/assets/js/bootstrap.bundle.min.js';
function getName(){
  const configData=localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null;
  if(configData?.fullName){
    return 'Welcome '+configData.fullName;
  }
  else{
    return '';
  }
}
</script>

<style>
@import '@/assets/css/style.css';
@import '@/assets/css/bootstrap.min.css';
@import '@/assets/css/dataTables.bootstrap5.min.css';
</style>
