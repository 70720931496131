import { createApp } from 'vue';
import App from './App.vue';
import boostrap from './assets/js/bootstrap.bundle.min.js'
import router from './router/router'; 
import store from './store'; 

createApp(App)
  .use(router)
  .use(store)
  .use(boostrap)
  .mount('#app');
