<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Roles</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>

        <table ref="rolesTable" class="table mt-3">
          <thead>
          <tr>
            <th>Role Id</th>
            <th>Role Name</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="role in roles" :key="role.masterRoleId">
            <td>{{ role.masterRoleId }}</td>
            <td>{{ role.roleName }}</td>
            <td>
              <p v-if="role.active" class="ongoing" style="text-align:center">{{ role.active }}</p>
              <p v-else class="progress1" style="text-align:center">false</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(role,true)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div id="dataModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Role</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <label class="right-content"> Role Name  </label>
                <input type="text" v-model="roleName" class="form-control" placeholder="Role Name">

                <label v-if="editFlag" class="right-content"> Active  </label>
                <select v-if="editFlag" v-model="updatedActive" class="form-select">
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="update">Save Changes</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import rolesAPI from '@/api/roles/roles';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      roles: [],
      editing: null,
      editFlag: false,
      roleName: null,
      updatedActive: null,
    };
  },
  methods: {
    openEditModal(role,flag) {
      this.editing = role;
      this.editFlag=flag;
      const modal = document.getElementById('dataModal');
      if(this.editFlag) {
        this.roleName = this.editing.roleName;
        this.updatedActive = this.editing.active;
      } else {
        this.roleName = "";
        this.updatedActive = true;
      }

      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeEditModal() {
      this.roleName = "";
      this.updatedActive = "";
      const modal = document.getElementById('dataModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchRoles() {
      try {
        const response = await rolesAPI.getRoles();
        this.roles = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    },
    async update()
    {

      const data = {
        roleName : this.roleName,
        active : this.updatedActive
      };

      try {
        let response = null;
        if(this.editFlag)
        {
          response = await rolesAPI.updateRoles(this.editing.masterRoleId,data);
        } else {
          response = await rolesAPI.addRoles(data);
        }

        if (response.data.success) {
          alert(response.data.message);
          await this.fetchRoles();
          this.closeEditModal();
        }

      } catch (error) {
        console.error('Error while updating/adding roles:', error);
        alert(error.response.data.message);
      }
    },
    getExportTitle() {
        return 'Roles';
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.rolesTable)) {
        $(this.$refs.rolesTable).DataTable().destroy();
      }
      let columnsToExport = [0,1,2];

      this.$nextTick(() => {
        $(this.$refs.rolesTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns:columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchRoles();
  },
};
</script>
