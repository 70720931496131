<!-- Country.vue -->
<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Country List</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button @click="openAddModal" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>
        <!-- State Table using DataTables -->
        <table ref="countryDataTable" class="table mt-3">
          <thead>
          <tr>
            <th>Country ID</th>
            <th>Country Name</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="country in countries" :key="country.countryId">
            <td>{{ country.countryId }}</td>
            <td>{{ country.countryName }}</td>
            <td>
              <p v-if="country.active" class="ongoing" style="text-align:center">{{ country.active }}</p>
              <p v-else class="progress1" style="text-align:center">{{ country.active }}</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(country)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img">
                </a>
                <!--
                <a href="#" @click="deleteState(state.stateId)">
                  <img :src="require('@/assets/images/delete.png')" width="25" height="25" class="btn-tbl-img">
                </a> -->
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <!-- Edit Modal -->
        <div id="editCountryModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Edit Country</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Country</label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedCountryName" class="form-control" placeholder="Country Name">
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Active </label>
                  <div class="col-sm-8">
                    <select v-model="countryActive" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="editCountry">Update</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Add Modal -->
        <div id="addCountryModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Add Header -->
              <div class="modal-header">
                <h5 class="modal-title">Add Country</h5>
                <button type="button" class="btn btn-danger" @click="closeAddModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Add Body -->
              <div class="modal-body">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Country</label>
                  <div class="col-sm-8">
                    <input type="text" v-model="newCountryName" class="form-control" placeholder="Country Name">
                  </div>
                </div>
              </div>
              <!-- Add Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeAddModal">Close</button>
                <button type="button" class="btn btn-primary" @click="addCountry">Save</button>
              </div>
            </div>
          </div>
        </div>
        <!--
          <form @submit.prevent="addState" class="mt-3">
            <label for="stateName">State Name:</label>
            <input v-model="newStateName" type="text" id="stateName" required class="form-control" />
            <button type="submit" class="btn btn-success mt-2">Add State</button>
          </form>
          -->
      </div>
    </div>
  </div>
</template>

<script>
import countryAPI from '@/api/location/country';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      newCountryName: '',
      countries: [],
      editingCountry: null,
      updatedCountryName: '',
      countryActive: '',
    };
  },
  methods: {
    openEditModal(country) {
      this.editingCountry = country;
      this.updatedCountryName = country.countryName;
      this.countryActive = country.active;
      const modal = document.getElementById('editCountryModal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeEditModal() {
      this.editingCountry = null;
      this.updatedCountryName = '';
      this.countryActive = '';
      const modal = document.getElementById('editCountryModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    openAddModal() {
      const modal = document.getElementById('addCountryModal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeAddModal() {
      this.newCountryName = '';
      const modal = document.getElementById('addCountryModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchCountries() {
      try {
        const response = await countryAPI.getCountries();
        this.countries = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching countries:', error);
        alert(error.response.data.message);
      }
    },
    async addCountry() {
      if (this.newCountryName.trim() !== '') {
        try {
          const response = await countryAPI.updateCountry({
            countryName: this.newCountryName,
            countryId: 0,
            active: true
          });
          alert(response.data.message);
          this.newCountryName = '';
          await this.fetchCountries();
          this.closeAddModal();
        } catch (error) {
          console.error('Error adding country:', error);
          alert(error.response.data.message);
        }
      } else {
        alert("country should not be empty");
      }
    },
    async editCountry() {
      if (this.updatedCountryName.trim() !== '') {
        try {
          const response = await countryAPI.updateCountry({
            countryName: this.updatedCountryName,
            countryId: this.editingCountry.countryId,
            active: this.countryActive
          });
          alert(response.data.message);
          await this.fetchCountries();
          this.closeEditModal();
        } catch (error) {
          console.error('Error updating country:', error);
          alert(error.response.data.message);
        }
      } else {
        alert("country should not be empty");
      }
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.countryDataTable)) {
        $(this.$refs.countryDataTable).DataTable().destroy();
      }
      const exportColumns = [0,1,2];
      this.$nextTick(() => {
        $(this.$refs.countryDataTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: 'CountryList',
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'csv',
              title: 'CountryList',
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'excel',
              title: 'CountryList',
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'pdf',
              title: 'CountryList',
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'print',
              title: 'CountryList',
              exportOptions: {
                columns: exportColumns
              },
            },
          ],
        });
      });
    },
  },
  created() {
    this.fetchCountries();
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
