// routers/router.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../components/LoginPage.vue';
import HelloPage from '../components/HelloPage.vue';
import CountryPage from '../components/Location/CountryPage.vue';
import StatePage from '../components/Location/StatePage.vue';
import CityPage from '../components/Location/CityPage.vue';
import AreaPage from '../components/Location/AreaPage.vue';
import AdminPage from '../components/Users/AdminPage.vue';
import VendorPage from '../components/Users/VendorPage.vue';
import OtpPage from '../components/OtherConfigs/OtpPage.vue'
import clientPage from '../components/Users/ClientPage.vue';
import BannerPage from  '../components/OtherConfigs/BannerPage.vue'
import faqPage from  '../components/OtherConfigs/faqPage.vue'
import PushNotificationsPage from '../components/OtherConfigs/PushNotificationsPage.vue'
import TaxesPage from  '../components/OtherConfigs/TaxesPage.vue'
import CustomerSupportDetailsPage from '../components/OtherConfigs/CustomerSupportDetailsPage.vue'
import HomePage from '../views/HomePage.vue';
import StorePage from  '../components/Stores/StorePage.vue'
import StoreMappingPage from  '../components/Stores/StoreMapping.vue'
import timingsPage from  '../components/Stores/TimingsPage.vue'
import holidayPage from  '../components/Stores/HolidayPage.vue'
import servicePage from  '../components/services/ServicePage.vue'
import serviceCategoryPage from  '../components/services/ServiceCategory.vue'
import serviceCategoryItemPage from  '../components/services/ServiceCategoryItems.vue'
import rolesPage from  '../components/Roles/RolesPage.vue'
import rolesToScreenMappingPage from  '../components/Roles/RolesToScreenMapping.vue'
import changePasswordPage from  '../components/ChangePassword.vue'
import store from '../store';
import DefaultPage from '../components/DefaultPage.vue';
import orderPage from '../components/OrdersPayment/OrdersPage.vue';
import paymentPage from '../components/OrdersPayment/PaymentPage.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/home',
    name: 'HomeTest',
    component: HelloPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/country',
    name: 'Country',
    component: CountryPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/state',
    name: 'State',
    component: StatePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/city',
    name: 'City',
    component: CityPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/area',
    name: 'Area',
    component: AreaPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admins',
    name: 'AdminPage',
    component: AdminPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/vendors',
    name: 'VendorPage',
    component: VendorPage,
    meta: { requiresAuth: true },
  },
  {
    path:'/banners',
    name:'BannerPage',
    component: BannerPage,
    meta:{ requiresAuth : true }
  },
  {
    path:'/push-notifications',
    name:'PushNotificationsPage',
    component : PushNotificationsPage,
    meta:{ requiresAuth: true}
  },
  {
    path:'/faqs',
    name:'faqPage',
    component : faqPage,
    meta : { requiresAuth: true}
  },
  {
    path:'/taxes',
    name:'TaxesPage',
    component: TaxesPage,
    meta: { requiresAuth: true }
  },
  {
    path:'/customer-support',
    name: 'CustomerSupportPage',
    component: CustomerSupportDetailsPage,
    meta : { requiresAuth: true }
  },
  {
    path:'/store-config',
    name: 'StorePage',
    component: StorePage,
    meta : { requiresAuth: true }
  },
  {
    path:'/service-store-mapping',
    name: 'StoreMappingPage',
    component: StoreMappingPage,
    meta : { requiresAuth: true }
  },
  {
    path:'/services',
    name: 'ServicePage',
    component: servicePage,
    meta : { requiresAuth: true }
  },
  {
    path:'/service-categories',
    name: 'serviceCategoryPage',
    component: serviceCategoryPage,
    meta : { requiresAuth: true }
  },
  {
    path:'/service-category-items',
    name: 'serviceCategoryItemPage',
    component: serviceCategoryItemPage,
    meta : { requiresAuth: true }
  },
  {
    path:'/roles-config',
    name: 'rolesPage',
    component: rolesPage,
    meta : { requiresAuth: true }
  },
  {
    path:'/roles-screen-mapping',
    name: 'rolesToScreenMappingPage',
    component: rolesToScreenMappingPage,
    meta : { requiresAuth: true }
  },
  {
    path:'/change-password',
    name: 'changePasswordPage',
    component: changePasswordPage,
    meta : { requiresAuth: true }
  },
  {
    path:'/clients',
    name: 'clientPage',
    component: clientPage,
    meta : { requiresAuth: true }
  },
  {
    path:'/otps',
    name: 'OtpPage',
    component: OtpPage,
    meta: {requiresAuth:true}
  },
  {
    path:'/orders',
    name: 'orderPage',
    component: orderPage,
    meta: {requiresAuth:true}
  },
  {
    path:'/payments',
    name: 'paymentPage',
    component: paymentPage,
    meta: {requiresAuth:true}
  },{
    path:'/holidays',
    name: 'holidayPage',
    component: holidayPage,
    meta: {requiresAuth:true}
  },{
    path:'/timings',
    name: 'TimingsPage',
    component: timingsPage,
    meta: {requiresAuth:true}
  },
  {
    path:'/:pathMatch(.*)*',
    name:'404Page',
    component:DefaultPage,
    meta:{requiresAuth:false}
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const allowedPages= localStorage.getItem('allowedPages')
  if (to.meta.requiresAuth && !store.state.auth.isAuthenticated) {
    // If the route requires authentication and the user is not authenticated, redirect to login page
    next({ name: 'Login' });
  } else if(allowedPages) {
    const arr=allowedPages.split(',')
    if(arr.includes(to.fullPath) || to.fullPath==='/' || to.fullPath==='/logout' ){
      next();
    }else{
      next({name:'Home'})
    }
  }
   else {
    next();
  }
});

export default router;
