import axios from 'axios';
import { BASE_URL } from '../apiUtils';
import { getAuthHeader} from '../apiUtils';
const dashboardAPI = {

    getDashboard(path) {
        return axios.get(`${BASE_URL}/dashboard/kpis?${path}`, { headers: getAuthHeader() });
    },
};

export default dashboardAPI;
