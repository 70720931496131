import axios from 'axios';
import { BASE_URL } from '../apiUtils';
import { getAuthHeader} from '../apiUtils'; 
const cityAPI = {
  getCities(countryId,stateId) {
    return axios.get(`${BASE_URL}/location/get-cities?country_id=${countryId}&state_id=${stateId}`, { headers: getAuthHeader() });
  },

  updateCity(updatedData) {
    return axios.post(`${BASE_URL}/location/update-city`, updatedData, { headers: getAuthHeader() });
  },
  getArea(countryId,stateId,cityId) {
    return axios.get(`${BASE_URL}/location/get-areas?country_id=${countryId}&state_id=${stateId}&city_id=${cityId}`, { headers: getAuthHeader() });
  },

  updateArea(updatedData) {
    return axios.post(`${BASE_URL}/location/update-area`, updatedData, { headers: getAuthHeader() });
  },
};

export default cityAPI;
