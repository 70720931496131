<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Category</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>

        <table ref="serviceTable" class="table mt-3">
          <thead>
          <tr>
            <th>Category Id</th>
            <th>Category Name</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="category in categories" :key="category.categoryId">
            <td>{{ category.categoryId }}</td>
            <td>{{ category.categoryName }}</td>
            <td>
              <p v-if="category.active" class="ongoing" style="text-align:center">{{ category.active }}</p>
              <p v-else class="progress1" style="text-align:center">{{ category.active }}</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(category,true)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div id="dataModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Service Category</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Category Name </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedCategoryName" class="form-control" placeholder="Category Name">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" v-if="editFlag"> Active </label>
                  <div class="row mb-1">
                    <select v-if="editFlag" v-model="updatedActive" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="update">Save Changes</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import serviceAPI from '@/api/service/service';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      categories: [],
      editingServiceCategory: null,
      editFlag: false,
      updatedCategoryName: null,
      updatedActive: null,
      file: '',
    };
  },
  methods: {
    openEditModal(serviceCategory, flag) {
      this.editingServiceCategory = serviceCategory;
      this.editFlag = flag;
      const modal = document.getElementById('dataModal');
      if (this.editFlag) {
        this.updatedCategoryName = this.editingServiceCategory.categoryName;
        this.updatedActive = this.editingServiceCategory.active;
      } else {
        this.updatedCategoryName = "";
        this.updatedActive = true;
      }

      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeEditModal() {
      this.updatedCategoryName = "";
      this.updatedActive = "";
      const modal = document.getElementById('dataModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchServiceCategory() {
      try {
        const response = await serviceAPI.getServiceCategory();
        this.categories = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error(error, 'Error fetching service category ');
        alert(error.response.data.message);
      }
    },
    async update() {

      const updateDate = {
        categoryName: this.updatedCategoryName,
        active: this.updatedActive,
      };
      try {
        let response = null;
        if (this.editFlag) {
          response = await serviceAPI.updateServiceCategory(this.editingServiceCategory.categoryId, updateDate);
        } else {
          response = await serviceAPI.addServiceCategory(updateDate);
        }
        if (response.data.success) {
          alert(response.data.message);
          await this.fetchServiceCategory();
          this.closeEditModal();
        }
      } catch (error) {
        console.error(error);
        alert(error.response.data.message);
      }
    },
    getExportTitle() {
      return 'Services';
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.serviceTable)) {
        $(this.$refs.serviceTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2];

      this.$nextTick(() => {
        $(this.$refs.serviceTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchServiceCategory();
  },
};
</script>
