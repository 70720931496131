import axios from 'axios';
import { BASE_URL } from '../apiUtils';
import { getAuthHeader} from '../apiUtils';
const orderPaymentAPI = {

    getOrders(path) {
        return axios.get(`${BASE_URL}/orders-and-payments/get-orders?`+path, { headers: getAuthHeader() });
    },

    getPayments(path) {
        return axios.get(`${BASE_URL}/orders-and-payments/get-payments?${path}`, { headers: getAuthHeader() });
    },

    getOrderDetails(orderId) {
        return axios.get(`${BASE_URL}/orders-and-payments/get-orders-details?order_id=${orderId}`, { headers: getAuthHeader() });
    },

    getOrderStatus() {
        return axios.get(`${BASE_URL}/orders-and-payments/get-order-status`, { headers: getAuthHeader() });
    },

    updateOrderStatus(orderId,status) {
        return axios.put(`${BASE_URL}/orders-and-payments/update-order-status/${orderId}/${status}`, null,{ headers: getAuthHeader() });
    },

    updateStoreName(orderId,storeName) {
        return axios.put(`${BASE_URL}/orders-and-payments/update-order-store/${orderId}/${storeName}`, null,{ headers: getAuthHeader() });
    },

};

export default orderPaymentAPI;
