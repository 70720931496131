<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Dashboard</h4>
      </div>
    </div>
      <div class="row pt-xxl-1" style="position:relative">
        <!-- Country Dropdown -->
        <div v-if="configData.masterRolesId !== '2' "
             class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1" >
          <label for="countryDropdown" style="margin-top:5px;">Select Country:</label>
          <select v-model="selectedCountry" @change="fetchStates(selectedCountry)"
                  class="form-select"
                  :disabled="configData.masterRolesId !== '1' ">
            <option value="0">ALL</option>
            <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
              {{ country.countryName }}
            </option>
          </select>
        </div>

        <!-- State Dropdown -->
        <div v-if="configData.masterRolesId !== '2' "
             class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
          <label for="stateDropdown" style="margin-top:5px;">Select State:</label>
          <select v-model="selectedState" @change="fetchCities(selectedCountry,selectedState)" id="stateDropdown"
                  class="form-select"
                  :disabled="configData.masterRolesId !== '1' ">
            <option value="0">ALL</option>
            <option v-for="state in states" :key="state.stateID" :value="state.stateId">{{ state.stateName }}</option>
          </select>
        </div>

        <div v-if="configData.masterRolesId !== '2' "
             class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
          <label style="margin-top:5px;">Select City:</label>
          <select v-model="selectedCity" @change="fetchStores(selectedCountry,selectedState,selectedCity)"
                  class="form-select"
                  :disabled="(configData.masterRolesId !== '1' && configData.masterRolesId !== '4')">
            <option value="0">ALL</option>
            <option v-for="city in cities" :key="city.cityId" :value="city.cityId">
              {{ city.cityName }}
            </option>
          </select>
        </div>

        <!-- Store Dropdown -->
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
          <label for="storeDropdown" style="margin-top:5px;">Select Store:</label>
          <select v-model="selectedStore" id="storeDropdown" class="form-select">
            <option value="0">ALL</option>
            <option v-for="store in stores" :key="store.storeId" :value="store.storeId">{{ store.storeName }}</option>
          </select>
        </div>

        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
          <label for="exampleInputPassword1">From Date</label>
          <input v-model="startDate" type="date" class="form-control" id="exampleInputPassword1">
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
          <label for="exampleInputPassword1" >To Date</label>
          <input v-model="endDate" type="date" class="form-control" id="exampleInputPassword1">
        </div>
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 " style="margin-top:25px;">
          <button @click="fetchDashboard" class="btn btn-success">Submit</button>
        </div>
      </div>
    <div class="row pt-4 pt-xxl-5 mt-2" style="padding-top: 1.5rem !important;">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="dash-box ms-2 ms-md-0">
          <img src="../assets/images/box-img1.png" width="40" height="40" class="box-img1">
          <p>Total Earnings</p>
          <h6>{{ dashboard.totalEarnings }}</h6>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12" v-if="admin">
        <div class="dash-box ms-2 ms-md-0 mt-5 mt-md-0">
          <img src="../assets/images/box-img2.png" width="40" height="40" class="box-img2">
          <p>New Customers</p>
          <h6>{{ dashboard.newCustomers }}</h6>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12" v-if="admin">
        <div class="dash-box ms-2 ms-md-0 mt-5 mt-lg-0">
          <img src="../assets/images/box-img3.png" width="40" height="40" class="box-img3">
          <p>New Store Owners</p>
          <h6>{{ dashboard.newVendors }}</h6>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="dash-box ms-2 ms-md-0 mt-5 mt-lg-0">
          <img src="../assets/images/box-img4.png" width="40" height="40" class="box-img4">
          <p>Total Orders</p>
          <h6>{{ dashboard.totalOrders }}</h6>
        </div>
      </div>
      <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="orders-status mt-3 table-responsive ms-2 ms-md-0">
          <h5>Total Orders Status: {{ orders.length }} </h5>
          <table class="table border order-table">
            <thead>
            <tr class="firstrow">
              <th>Order Id</th>
              <th>Order By</th>
              <th>Status</th>
              <th>Store Name</th>
              <th>Order Quantity</th>
              <th>Created Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in orders" :key="order.orderId">
              <td>{{ order.orderId }}</td>
              <td>{{ order.orderBy }}</td>
              <td>{{ order.orderStatus }}</td>
              <td>{{ order.storeName }}</td>
              <td>{{ order.orderQuantity }}</td>
              <td>{{ order.createdTime }}</td>

            </tr>

            </tbody>
          </table>
        </div>
      </div>
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="b-top-img mt-3 ms-2 ms-md-0">
          <img src="../assets/images/b-top-img.png" class="img-fluid">
        </div>
        <div class="overview ms-2 ms-md-0">
          <div class="row">
            <div class="col-6 mt-3">
              <div class="bottom-img-parts d-flex">
                <div class="b-img1"><img src="../assets/images/b-img1.png" width="35" height="35"></div>
                <div class="b-img-text ms-2">
                  <p>{{ todayKpi.totalOrders }}</p>
                  <p>Total orders</p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-3">
              <div class="bottom-img-parts d-flex">
                <div class="b-img2"><img src="../assets/images/b-img2.png" width="35" height="35"></div>
                <div class="b-img-text ms-2">
                  <p>{{ todayKpi.delivered }}</p>
                  <p>Delivered</p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="bottom-img-parts d-flex">
                <div class="b-img3"><img src="../assets/images/b-img3.png" width="35" height="35"></div>
                <div class="b-img-text ms-2">
                  <p>{{ todayKpi.pickUp }}</p>
                  <p>Pickup's</p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="bottom-img-parts d-flex">
                <div class="b-img4"><img src="../assets/images/b-img4.png" width="35" height="35"></div>
                <div class="b-img-text ms-2">
                  <p>{{ todayKpi.readyToDeliver }}</p>
                  <p>Ready to Deliver</p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="bottom-img-parts d-flex">
                <div class="b-img5"><img src="../assets/images/b-img5.png" width="35" height="35"></div>
                <div class="b-img-text ms-2">
                  <p>{{ todayKpi.inProgress }}</p>
                  <p>In-Progress</p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="bottom-img-parts d-flex">
                <div class="b-img6"><img src="../assets/images/b-img6.png" width="35" height="35"></div>
                <div class="b-img-text ms-2">
                  <p>{{ todayKpi.pending }}</p>
                  <p>Pending</p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="bottom-img-parts d-flex">
                <div class="b-img2"><img src="../assets/images/b-img7.png" width="35" height="35"></div>
                <div class="b-img-text ms-2">
                  <p>{{ todayKpi.confirmed }}</p>
                  <p>Confirmed</p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="bottom-img-parts d-flex">
                <div class="b-img5"><img src="../assets/images/b-img8.png" width="35" height="35"></div>
                <div class="b-img-text ms-2">
                  <p>{{ todayKpi.cancelled }}</p>
                  <p>Cancelled</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import dashboardAPI from '@/api/dashboard/dashboard.js';
import orderPaymentAPI from '@/api/OrdersPayment/OrdersPayment';
import storeAPI from '@/api/store/store.js';
import countryAPI from '@/api/location/country.js';
import stateAPI from '@/api/location/state.js';
import cityAPI from '@/api/location/cities.js';


export default {
  data() {
    return {
      dashboard: '',
      todayKpi: '',
      startDate: '',
      selectedOwner: 0,

      owners: [],

      endDate: '',
      orders: '',
      countries: [],
      states: [],
      cities: [],
      stores: [],
      selectedCountry: 0,
      selectedState: 0,
      selectedCity: 0,
      selectedStore: 0,
      configData: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null,
      admin: false,
    };
  },
  methods: {
    async fetchOwners() {
      try {
        const response = await storeAPI.fetchUsers();
        this.owners = response.data;
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    },
    async fetchCountries() {
      try {
        const response = await countryAPI.getCountries();
        this.countries = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching countries:', error);
      
      }
    },
    async fetchStates(country) {
      try {
        const response = await stateAPI.getStates(country);
        this.states = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching states:', error);
       
      }
    },
    async fetchCities(country, state) {
      try {
        const response = await cityAPI.getCities(country, state);
        this.cities = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching cities:', error);
        alert(error.response.data.message);
      }
    },
    async fetchStores(country, state, city) {
      try {
        let path = "";
        if (country !== null && country !== 0) {
          path += `country_id=${country}`;
        }
        if (state !== null && state !== 0) {
          path += `&state_id=${state}`;
        }
        if (city !== null && city !== 0) {
          path += `&city_id=${city}`;
        }
        if (country === 'undefined') {
          return false;
        }
        if (this.configData.masterRolesId === '2') {
          path += `&owner_id=${this.configData.userId}`;
        }
        const response = await storeAPI.getStores(path, true);
        this.stores = response.data;
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    },
    async fetchDashboard() {
      try {
        let path = "";
        if (!this.startDate) {
          this.startDate = new Date().toISOString().split('T')[0];
        }
        if (!this.endDate) {
          this.endDate = new Date().toISOString().split('T')[0];
        }

        this.startDate = this.startDate.split('T')[0];
        this.endDate = this.endDate.split('T')[0];

        const formattedStartDate = this.startDate + ' 00:00:00';
        const formattedEndDate = this.endDate + ' 23:59:59';

        path += `start_date=${formattedStartDate}`;

        path += `&end_date=${formattedEndDate}`;
        if (this.selectedOwner !== null) {
          path += `&user_id=${this.selectedOwner}`;
        }
        if (this.selectedCountry !== null && this.selectedCountry !== 0) {
          path += `country_id=${this.selectedCountry}`;
        }
        if (this.selectedState !== null && this.selectedState !== 0) {
          path += `&state_id=${this.selectedState}`;
        }
        if (this.selectedCity !== null && this.selectedCity !== 0) {
          path += `&city_id=${this.selectedCity}`;
        }

        if (this.selectedStore !== null && this.selectedStore !== 0) {
          path += `&store_id=${this.selectedStore}`;
        }
        if (this.configData.masterRolesId === '2') {
          path += `&owner_id=${this.configData.userId}`;
        }


        const response = await dashboardAPI.getDashboard(path);

        this.dashboard = response.data;
        this.todayKpi = this.dashboard.todayKpi;
        const orderResponse = await orderPaymentAPI.getOrders(path);
        this.orders = orderResponse.data;
      } catch (error) {
        console.error('Error fetching Dashboard:', error);
      }
    },
  },
  created() {
    if (this.configData) {
      this.admin = this.configData.admin;
    }
    if (this.configData.masterRolesId === '4') {
      this.fetchCountries();
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId, this.configData.stateId);
      this.fetchStores(this.selectedCountry, this.selectedState, null);
    } else if (this.configData.masterRolesId === '3') {
      this.fetchCountries();
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId, this.configData.stateId);
      this.selectedCity = this.configData.cityId;
      this.fetchStores(this.selectedCountry, this.selectedState, this.selectedCity);
    } else if (this.configData.masterRolesId === '1') {
      this.fetchCountries();
      this.selectedCountry = '1';
      this.fetchStates(this.selectedCountry);
    } else if (this.configData.masterRolesId === '2') {
      this.fetchStores(null, null, null);
    }

    this.fetchDashboard();

  },
};
</script>
