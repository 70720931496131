import axios from 'axios';
import { BASE_URL } from './apiUtils';
import { getAuthHeader, refreshToken } from './apiUtils';

const homeAPI = {
  getMenuItems() {
    return axios.get(`${BASE_URL}/menu/get-menu-items`, { headers: getAuthHeader() });
  },
  async refreshToken() {
      const token = await refreshToken();
      return token;
  },
};

export default homeAPI;

