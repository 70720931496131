//store/modules/home.js
const state = {
  menuItems: null,
  selectedMenuItem: null, // New state to track the selected menu item
};

const mutations = {
  SET_MENU_ITEMS(state, menuItems) {
    state.menuItems = menuItems;
  },
  SET_SELECTED_MENU_ITEM(state, selectedMenuItem) {
    state.selectedMenuItem = selectedMenuItem;
  },
};

const actions = {
  setMenuItems({ commit }, menuItems) {
    commit('SET_MENU_ITEMS', menuItems);
  },
  setSelectedMenuItem({ commit }, selectedMenuItem) {
    commit('SET_SELECTED_MENU_ITEM', selectedMenuItem);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
