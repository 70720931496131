import axios from 'axios';
import { BASE_URL } from '../apiUtils';
import { getAuthHeader} from '../apiUtils';
const rolesAPI = {

    getRoles() {
        return axios.get(`${BASE_URL}/roles/get-roles`, { headers: getAuthHeader() });
    },

    getMapRolesToScreen(roleId,menuId) {
        return axios.get(`${BASE_URL}/roles/map-role-to-screen/role/${roleId}/screen/${menuId}`, { headers: getAuthHeader() });
    },

    getRoleToScreen(roleId) {
        return axios.get(`${BASE_URL}/roles/role-to-screen-mapping?roleId=`+roleId, { headers: getAuthHeader() });
    },

    addRoles(data) {
        return axios.post(`${BASE_URL}/roles/add-role`, data,{ headers: getAuthHeader() });
    },

    updateRoles(id,data) {
        return axios.put(`${BASE_URL}/roles/update-role/`+id, data,{ headers: getAuthHeader() });
    },
};

export default rolesAPI;
