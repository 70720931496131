import axios from 'axios';
import { BASE_URL, getAuthHeader } from '../apiUtils';

const countryAPI = {
  getCountries() {
    return axios.get(`${BASE_URL}/location/get-countries`, { headers: getAuthHeader() });
  },

  updateCountry(updatedData) {
    return axios.post(`${BASE_URL}/location/update-country`, updatedData, { headers: getAuthHeader() });
  },

};

export default countryAPI;
