//store/index.js
import { createStore } from 'vuex';
import authModule from './modules/auth';
import homeModule from './modules/home';


export default createStore({
  modules: {
    auth: authModule,
    home: homeModule,
    // Other modules can be added here if needed
  },
});
