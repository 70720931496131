import axios from "axios";
import { BASE_URL, getAuthHeader } from  '../apiUtils';

const customerSupportAPI = {
    getDetails(){
        return axios.get(`${BASE_URL}/other-configs/get-customer-support-details`,{headers: getAuthHeader()});
    },
    updateDetails(data){
        return axios.post(`${BASE_URL}/other-configs/update-customer-support-details`,data,{headers: getAuthHeader()})
    }
}

export default customerSupportAPI