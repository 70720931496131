import axios from "axios";
import { BASE_URL,getAuthHeader } from "../apiUtils";

const faqAPI = {
    getfaq(){
        return axios.get(`${BASE_URL}/other-configs/get-faqs`,{headers: getAuthHeader()});

    },
    deletefaq(id){
        return axios.delete(`${BASE_URL}/other-configs/delete-faq/${id}`,{headers: getAuthHeader()});
    },
    updatefaq(data){
        return axios.post(`${BASE_URL}/other-configs/update-faqs`,data,{headers:getAuthHeader()});
    }
}

export default faqAPI