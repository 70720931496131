import axios from 'axios';
import { BASE_URL } from '../apiUtils';
import { getAuthHeader} from '../apiUtils';
const serviceAPI = {

    getServices(path) {
        return axios.get(`${BASE_URL}/services/get-services`, { headers: getAuthHeader() });
    },

    addServices(updatedData) {
        const headers = {
            ...getAuthHeader(),
            'Content-Type': 'multipart/form-data'
        };
        return axios.post(`${BASE_URL}/services/add-service`, updatedData, { headers });
    },

    updateServices(id,updatedData) {
        const headers = {
            ...getAuthHeader(),
            'Content-Type': 'multipart/form-data'
        };
        return axios.put(`${BASE_URL}/services/update-service/`+id, updatedData, { headers });
    },

    getServiceCategory() {
        return axios.get(`${BASE_URL}/services/get-service-categories`, { headers: getAuthHeader() });
    },

    addServiceCategory(updatedData) {
        return axios.post(`${BASE_URL}/services/add-service-category`, updatedData, { headers: getAuthHeader() });
    },

    updateServiceCategory(id,updatedData) {
        return axios.put(`${BASE_URL}/services/update-service-category/`+id, updatedData, { headers: getAuthHeader() });
    },

    getServiceCategoryItem(path) {
        return axios.get(`${BASE_URL}/services/get-service-category-items?`+path, { headers: getAuthHeader() });
    },

    addServiceCategoryItem(updatedData) {
        const headers = {
            ...getAuthHeader(),
            'Content-Type': 'multipart/form-data'
        };
        return axios.post(`${BASE_URL}/services/add-service-category-item`, updatedData, { headers });
    },

    updateServiceCategoryItem(id,updatedData) {
        const headers = {
            ...getAuthHeader(),
            'Content-Type': 'multipart/form-data'
        };
        return axios.put(`${BASE_URL}/services/update-service-category-item/`+id, updatedData, { headers });
    },
};

export default serviceAPI;
