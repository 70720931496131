<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Area List</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <!-- Country Dropdown -->
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="countryDropdown" style="margin-top:5px;">Select Country:</label>
        <select v-model="selectedCountry" @change="fetchStates(selectedCountry)" id="countryDropdown" class="form-select"
                :disabled="configData.masterRolesId !== '1' ">
          <option value="0">ALL</option>
          <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
            {{ country.countryName }}
          </option>
        </select>
      </div>

      <!-- State Dropdown -->
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label for="stateDropdown" style="margin-top:5px;">Select State:</label>
        <select v-model="selectedState" @change="fetchCities(selectedCountry,selectedState)" id="stateDropdown" class="form-select"
                :disabled="configData.masterRolesId !== '1' ">
          <option value="0">ALL</option>
          <option v-for="state in states" :key="state.stateID" :value="state.stateId">{{ state.stateName }}</option>
        </select>
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-5">
        <label style="margin-top:5px;">Select City:</label>
        <select v-model="selectedCity" @change="fetchAreas(selectedCountry,selectedState,selectedCity)" id="countryDropdown" class="form-select"
                :disabled="(configData.masterRolesId !== '1' && configData.masterRolesId !== '4')">
          <option value="0">ALL</option>
          <option v-for="city in cities" :key="city.cityId" :value="city.cityId">
            {{ city.cityName }}
          </option>
        </select>
      </div>

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
          Add
        </button>
        <!-- City Table using DataTables -->
        <table ref="citiesDataTable" class="table mt-3">
          <thead>
          <tr>
            <th>Country</th>
            <th>State</th>
            <th>City</th>
            <th>Area name</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="area in areas" :key="area.areaId">
            <td>{{ area.countryName }}</td>
            <td>{{ area.stateName }}</td>
            <td>{{ area.cityName }}</td>
            <td>{{ area.areaName }}</td>
            <td><p v-if="area.active" class="ongoing">{{ area.active }}</p>
              <p v-else class="progress1">{{ area.active }}</p>
            </td>
            <td>
              <div class="three-images d-flex">
                <a href="#" @click="openEditModal(area,true)">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img">
                </a>
                <!--
                <a href="#" @click="deleteState(state.stateId)">
                <img :src="require('@/assets/images/delete.png')" width="25" height="25" class="btn-tbl-img">
                </a> -->
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <!-- Edit Modal -->
        <div id="editCityModal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 class="modal-title">Edit City</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Country </label>
                  <div class="col-sm-8">
                    <select v-model="updatedCountry" @change="fetchStates(updatedCountry)" id="countryDropdown" class="form-select"
                            :disabled="configData.masterRolesId !== '1' ">
                      <option value="0">ALL</option>
                      <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
                        {{ country.countryName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">State </label>
                  <div class="col-sm-8">
                    <select :disabled="configData.masterRolesId !== '1' " v-model="updatedState" class="form-select"
                            @change="fetchCities(updatedCountry,updatedState)" >
                      <option value="0">Select State</option>
                      <option v-for="state in states" :key="state.stateID" :value="state.stateId">{{
                          state.stateName
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> City </label>
                  <div class="col-sm-8">
                    <select v-model="updatedCity"  id="countryDropdown" class="form-select"
                            :disabled="(configData.masterRolesId !== '1' && configData.masterRolesId !== '4')">
                      <option value="0">Select City</option>
                      <option v-for="city in cities" :key="city.cityId" :value="city.cityId">
                        {{ city.cityName }}
                      </option>
                    </select>
                  </div>
                </div> <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"> Area </label>
                  <div class="col-sm-8">
                    <input type="text" v-model="updatedAreaName" class="form-control" placeholder="Area Name">
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" v-if="editFlag"> Active </label>
                  <div class="col-sm-8">
                    <select v-if="editFlag" v-model="active" class="form-select">
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="addUpdateArea">Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countryAPI from '@/api/location/country.js';
import stateAPI from '@/api/location/state.js';
import cityAPI from '@/api/location/cities.js';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      countries: [],
      states: [],
      cities: [],
      areas: [],
      selectedCountry: 0,
      selectedState: 0,
      selectedCity: 0,
      editingArea: null,
      updatedAreaName: '',
      active: '',
      editFlag: false,
      updatedCountry: 0,
      updatedState: 0,
      updatedCity: 0,
      configData: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null,
    }
  },
  methods: {
    openEditModal(area, flag) {
      this.editFlag = flag;
      if (this.editFlag) {
        this.editingArea = area;
        this.updatedAreaName = area.areaName;
        this.updatedState = this.selectedState;
        this.updatedCity = this.selectedCity;
        this.updatedCountry = this.selectedCountry;
        this.active = area.active;
      } else {
        this.active = true;
        this.updatedState = this.selectedState;
        this.updatedCity = this.selectedCity;
        this.updatedCountry = this.selectedCountry;
      }
      const modal = document.getElementById('editCityModal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeEditModal() {
      this.editingArea = null;
      this.updatedAreaName = '';
      this.updatedState = '0';
      this.updatedCountry = '1';
      this.updatedCity = '0';
      this.active = '';
      const modal = document.getElementById('editCityModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchCountries() {
      try {
        const response = await countryAPI.getCountries();
        this.countries = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching countries:', error);
        alert(error.response.data.message);
      }
    },
    async fetchStates(country) {
      try {
        const response = await stateAPI.getStates(country);
        this.states = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching states:', error);
        alert(error.response.data.message);
      }
    },
    async fetchCities(country,state) {
      try {
        const response = await cityAPI.getCities(country, state);
        this.cities = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching cities:', error);
        alert(error.response.data.message);
      }
    },
    async fetchAreas(country,state,city) {
      try {
        const response = await cityAPI.getArea(country, state, city);
        this.areas = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching cities:', error);
        alert(error.response.data.message);
      }
    },
    async addUpdateArea() {
      try {
        const response = await cityAPI.updateArea({
          areaName: this.updatedAreaName,
          cityId: this.updatedCity,
          areaId: this.editFlag ? this.editingArea.areaId : 0,
          stateId: this.updatedState,
          countryId: this.updatedCountry,
          active: this.active,
        });
        alert(response.data.message);
        if (response.data.success) {
          await this.fetchAreas(this.updatedCountry,this.updatedState,this.updatedCity);
          this.closeEditModal();
        }
      } catch (error) {
        console.error('Error adding cities:', error);
        alert(error.response.data.message);
      }
    },
    getExportTitle() {
      const selectedCountry = this.countries.find(country => country.countryId === this.selectedCountry);
      const selectedState = this.states.find(state => state.stateId === this.selectedState);
      if (selectedCountry && selectedState) {
        return `${selectedCountry.countryName.replace(/ /g, '_')}_${selectedState.stateName.replace(/ /g, '_')}_states`;
      } else {
        return 'Exported_cities';
      }
    },
    initializeDataTable() {

      if ($.fn.DataTable.isDataTable(this.$refs.citiesDataTable)) {
        $(this.$refs.citiesDataTable).DataTable().destroy();
      }
      const exportColumns = [0, 1, 2, 3, 4]
      this.$nextTick(() => {
        $(this.$refs.citiesDataTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: exportColumns
              },
            },
          ],
        });
      });
    },
  },
  created() {
    this.fetchCountries();
    if (this.configData.masterRolesId === '4') {
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.selectedCountry,this.selectedState);
      this.fetchAreas(this.selectedCountry, this.selectedState, 0);
    } else if (this.configData.masterRolesId === '3') {
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId,this.configData.stateId);
      this.selectedCity = this.configData.cityId;
      this.fetchAreas(this.selectedCountry, this.selectedState, this.selectedCity);
    } else {
      this.selectedCountry = 1;
      this.fetchStates(1);
    }
  }
  ,
}
</script>