<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <ul @click="navigateToTestPage">Test</ul>
        <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
          <li class="nav-item" v-for="(menuItem, index) in menuItems" :key="index">
            <a href="#" data-bs-toggle="collapse" :data-bs-target="'#submenu-' + index" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-speedometer2"></i> <span class="ms-1 d-none d-sm-inline">{{ menuItem.name }}</span>
              <span v-if="menuItem.child && menuItem.child.length" class="bi bi-chevron-down"></span>
            </a>
            <ul class="collapse nav flex-column ms-1" :id="'submenu-' + index" data-bs-parent="#menu">
              <li class="w-100" v-for="(subMenuItem, subIndex) in menuItem.child" :key="subIndex">
                <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">{{ subMenuItem.name }}</span> </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      
      <main role="main" class="col-md-9 ml-sm-auto col-lg-9 px-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 class="h2">Hello Page</h1>
          <div class="btn-toolbar mb-2 mb-md-0">
          </div>
        </div>

        <template v-if="user">
          <p>Welcome, {{ user.fullName }}!</p>
          <p>Mobile Number: {{ user.emailId }}</p>
        </template>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import homeAPI from '@/api/home';

export default {
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('home', ['menuItems']),
  },
  created() {
    homeAPI.getMenuItems()
      .then(response => {
        this.setMenuItems(response.data);
      })
      .catch(error => {
        console.error('Error fetching menu items:', error);
      });
  },
  methods: {
    ...mapActions('home', ['setMenuItems']),
    handleMenuClick(url) {
    },
    navigateToTestPage() {
      this.$router.push('/test'); 
    },
  },
};
</script>
