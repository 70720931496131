<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">  
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Clients</h4>
      </div>
    </div> 
    <div class="row pt-4 pt-xxl-5" style="position:relative">  
     

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
     
        <!-- Client Table using DataTables -->
        <table ref="clientsDataTable" class="table mt-3">
          <thead>
            <tr>
              <th>User ID</th>
              <th>User Name</th>
              <th>Auth Type</th>
              <th>Email Id</th>
              <th>Mobile Number</th>
              <th>Email Verified</th>
              <th>Mobile Verified</th>
              <th>Active</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in clients" :key="client.userId">
              <td>{{ client.userId }}</td>
              <td>{{ client.userName }}</td>
              <td>{{ client.authType }}</td>
              <td>{{ client.emailId }}</td>
              <td>{{ client.mobileNumber }}</td>
              <td>{{ client.emailVerified }}</td>
              <td>{{ client.mobileVerified }}</td>
              <td>
                <p v-if="client.active" class="ongoing" style="text-align:center">{{ client.active }}</p>
                <p v-else class="progress1" style="text-align:center">false</p>
                </td>
              <td>
                <div class="three-images d-flex">
                
                  <a href="#" @click="openModal(client,'block')" v-if="client.active">
                    <img :src="require('@/assets/images/block.png')" width="25" height="25" class="btn-tbl-black" alt="">
                  </a>
                  <a href="#" @click="openModal(client,'unblock')" v-else>
                    <img :src="require('@/assets/images/unlocked.png')" width="25" height="25" class="btn-tbl-black" alt="">
                  </a>
                
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        

        <!--Customize modal-->
        <div id="modal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                
                  <span v-if="action==='block'">
                    Block Client
                  </span>
                  <span v-if="action==='unblock'">
                    Unblock Client
                  </span>
                </h5>
                <span @click="closeModal">X</span>
              </div>
              <div class="modal-body">
              
                <div v-if="action==='block' || action==='unblock'">
                  <span>
                    Are you sure you want to {{ action==='block'?'block':'unblock' }} {{ fullName }}?
                  </span>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                <button type="button" class="btn btn-primary" @click="modalSubmit">
                
                  <span v-if="action==='block'">
                    Block
                  </span>
                  <span v-if="action==='unblock'">
                    Unblock
                  </span>
                </button>
              </div> 
            </div>
          </div>
        </div>
     
      </div>
    </div>
  </div>
</template>

<script>
import userAPI from '@/api/user';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

export default {
  data() {
    return {
      editingObject: null,
      clients: [],
      action:''
    };
  },
  methods: {
    openModal(client,actionType) {
      this.action=actionType
      if(client!==null){
        this.editingObject = client;
       
      }else{

      }
      const modal = document.getElementById('modal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeModal() {
      const modal = document.getElementById('modal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
         modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
      this.editingObject = null;

    },
    async modalSubmit(){
      if(this.action==='edit' || this.action==='add'){
        if(this.fullName.trim()!=='' && this.emailId.trim()!==''){
          try{
            if(this.action==='edit'){
              const response = await userAPI.updateVendor({
                fullName: this.fullName,
                emailId:this.emailId,
                aadhaarNumber:this.aadhaarNumber,
                addressLine:this.addressLine,
                primaryMobileNumber:this.mobileNumber,
                secondaryMobileNumber:this.alternativeMobileNumber,
                aadhaarPicUrl:this.aadhaarPicUrl,
                roleId:2
              },this.editingObject.userId)
              alert(response.data.message);
              if (response.data.success) {
                await this.fetchVendors(); // Refresh states after editing
                this.closeModal();
              }
            }
            else{
              const response = await userAPI.addVendor({
                fullName: this.fullName,
                emailId:this.emailId,
                aadhaarNumber:this.aadhaarNumber,
                addressLine:this.addressLine,
                primaryMobileNumber:this.mobileNumber,
                secondaryMobileNumber:this.alternativeMobileNumber,
                aadhaarPicUrl:this.aadhaarPicUrl,
                roleId:2
              })
              alert(response.data.message)
              if(response.data.success){
                await this.fetchVendors();
                this.closeModal();
              }
            }
          }catch(error){
            console.error("Error updating clients:",error);
          }
        }
        else{
          alert('Type both email ID and full Name')
        }
      }
      else {
        try{
          const response = await userAPI.patchClients(this.editingObject.userId)
          alert(response.data.message)
          if(response.data.success){
            await this.fetchClients();
            this.closeModal();
          }
        } catch(error){
          console.error("error blocking clients:",error)
        }
      }
      
    },
    async fetchClients() {
      try {
        const response = await userAPI.getClients();
        this.clients = response.data;
        this.initializeDataTable();
        
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    },
    initializeDataTable() {
      // Destroy existing DataTable
      if ($.fn.DataTable.isDataTable(this.$refs.clientsDataTable)) {
        $(this.$refs.clientsDataTable).DataTable().destroy();
      }
      // Initialize DataTable when the component is mounted or when states change
      this.$nextTick(() => {
        $(this.$refs.clientsDataTable).DataTable({
            mark:true,
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: 'clientList',
              exportOptions: {
                columns: [0,1,2,3,4]
              },
            },
            {
              extend: 'csv',
              title: 'vendorList',
              exportOptions: {
                columns: [0,1,2,3,4] 
              },
            },
            {
              extend: 'excel',
              title: 'vendorList',
              exportOptions: {
                columns: [0,1,2,3,4] 
              },
            },
            {
              extend: 'pdf',
              title: 'vendorList',
              exportOptions: {
                columns: [0,1,2,3,4] 
              },
            },
            {
              extend: 'print',
              title: 'vendorList',
              exportOptions: {
                columns: [0,1,2,3,4] 
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchClients();
  },
};
</script>
