<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Payments</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <!-- Country Dropdown -->
      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label for="countryDropdown" style="margin-top:5px;">Select Country:</label>
        <select v-model="selectedCountry" @change="fetchStates(selectedCountry)"
                class="form-select"
                :disabled="configData.masterRolesId !== '1' ">
          <option value="0">ALL</option>
          <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
            {{ country.countryName }}
          </option>
        </select>
      </div>

      <!-- State Dropdown -->
      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label for="stateDropdown" style="margin-top:5px;">Select State:</label>
        <select v-model="selectedState" @change="fetchCities(selectedCountry,selectedState)" id="stateDropdown"
                class="form-select"
                :disabled="configData.masterRolesId !== '1' ">
          <option value="0">ALL</option>
          <option v-for="state in states" :key="state.stateID" :value="state.stateId">{{ state.stateName }}</option>
        </select>
      </div>

      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label style="margin-top:5px;">Select City:</label>
        <select v-model="selectedCity" @change="fetchStores(selectedCountry,selectedState,selectedCity)"
                class="form-select"
                :disabled="(configData.masterRolesId !== '1' && configData.masterRolesId !== '4')">
          <option value="0">ALL</option>
          <option v-for="city in cities" :key="city.cityId" :value="city.cityId">
            {{ city.cityName }}
          </option>
        </select>
      </div>

      <!-- Store Dropdown -->
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label for="storeDropdown" style="margin-top:5px;">Select Store:</label>
        <select v-model="selectedStore" id="storeDropdown" class="form-select">
          <option value="0">ALL</option>
          <option v-for="store in stores" :key="store.storeId" :value="store.storeId">{{ store.storeName }}</option>
        </select>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label for="startDate" style="margin-top:5px;">Start Date</label>
        <input type="date" v-model="startDate" class="form-control control-fr" id="startDate">
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label for="endDate" style="margin-top:5px;">End Date</label>
        <input type="date" v-model="endDate" class="form-control control-fr" id="endDate">
      </div>

<!--      <div v-if="configData.masterRolesId === '1' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label for="ownerDropdown" style="margin-top:5px;">Store Owner</label>
        <select v-model="selectedOwner" id="ownerDropdown" class="form-select">
          <option value="0">ALL</option>
          <option v-for="owner in owners" :key="owner.userId" :value="owner.userId">{{ owner.fullName }}</option>
        </select>
      </div>-->

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2" style="margin-top:25px;">
        <button @click="fetchOrderPayments" class="btn btn-success">Submit</button>
      </div>

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <!--        <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
                  Add
                </button>-->

        <table ref="ordersTable" class="table mt-3">
          <thead>
          <tr>
            <th>Id</th>
            <th>Order Id</th>
            <th>Order By</th>
            <th>Payment Transaction Id</th>
            <th>Payment Status</th>
            <th>Payment Mode</th>
            <th>Order Amount</th>
            <th>Order Quantity</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="payment in payments" :key="payment.orderPaymentId">
            <td>{{ payment.orderPaymentId }}</td>
            <td>{{ payment.orderId }}</td>
            <td>{{ payment.orderBy }}</td>
            <td>{{ payment.paymentTransactionId }}</td>
            <td>{{ payment.paymentStatus }}</td>
            <td>{{ payment.paymentMode }}</td>
            <td>{{ payment.orderAmount }}</td>
            <td>{{ payment.orderQuantity }}</td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import orderPaymentAPI from '@/api/OrdersPayment/OrdersPayment';
import storeAPI from '@/api/store/store.js';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
import countryAPI from "@/api/location/country";
import stateAPI from "@/api/location/state";
import cityAPI from "@/api/location/cities";

export default {
  data() {
    return {
      owners: [],
      payments: [],
      countries: [],
      states: [],
      cities: [],
      selectedCountry: 0,
      selectedState: 0,
      selectedCity: 0,
      selectedStore: 0,
      stores: [],
      selectedOwner: 0,
      startDate: '',
      endDate: '',
      configData: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null,
    };
  },
  methods: {
    async fetchOwners() {
      try {
        const response = await storeAPI.fetchUsers();
        this.owners = response.data;
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    },
    async fetchCountries() {
      try {
        const response = await countryAPI.getCountries();
        this.countries = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching countries:', error);
        alert(error.response.data.message);
      }
    },
    async fetchStates(country) {
      try {
        const response = await stateAPI.getStates(country);
        this.states = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching states:', error);
        alert(error.response.data.message);
      }
    },
    async fetchCities(country, state) {
      try {
        const response = await cityAPI.getCities(country, state);
        this.cities = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching cities:', error);
        alert(error.response.data.message);
      }
    },
    async fetchStores(country, state, city) {
      try {
        let path = "";
        if (country !== null && country !== 0) {
          path += `country_id=${country}`;
        }
        if (state !== null && state !== 0) {
          path += `&state_id=${state}`;
        }
        if (city !== null && city !== 0) {
          path += `&city_id=${city}`;
        }
        if (country === 'undefined') {
          return false;
        }
        if (this.configData.masterRolesId === '2') {
          path += `&owner_id=${this.configData.userId}`;
        }
        const response = await storeAPI.getStores(path, true);
        this.stores = response.data;
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    },
    async fetchOrderPayments() {
      try {
        if (!this.startDate) {
          const oneMonthAgo = new Date();
          oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
          this.startDate = oneMonthAgo.toISOString().split('T')[0];
        }
        if (!this.endDate) {
          this.endDate = new Date().toISOString().split('T')[0];
        }

        this.startDate = this.startDate.split('T')[0];
        this.endDate = this.endDate.split('T')[0];

        const formattedStartDate = this.startDate + ' 00:00:00';
        const formattedEndDate = this.endDate + ' 23:59:59';

        let path = "";
        if (this.startDate !== null) {
          path += `start_date=${formattedStartDate}`;
        }
        if (this.endDate !== null) {
          path += `&end_date=${formattedEndDate}`;
        }
    /*    if (this.selectedOwner !== null && this.selectedOwner !== 0) {
          path += `&user_id=${this.selectedOwner}`;
        }*/
        if (this.selectedCountry !== null && this.selectedCountry !== 0) {
          path += `&country_id=${this.selectedCountry}`;
        }
        if (this.selectedState !== null && this.selectedState !== 0) {
          path += `&state_id=${this.selectedState}`;
        }
        if (this.selectedCity !== null && this.selectedCity !== 0) {
          path += `&city_id=${this.selectedCity}`;
        }

        if (this.selectedStore !== null && this.selectedStore !== 0) {
          path += `&store_id=${this.selectedStore}`;
        }
        if (this.configData.masterRolesId === '2') {
          path += `&owner_id=${this.configData.userId}`;
        }

        const response = await orderPaymentAPI.getPayments(path);
        this.payments = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    },
    getExportTitle() {
      return 'Services';
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.ordersTable)) {
        $(this.$refs.ordersTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2];

      this.$nextTick(() => {
        $(this.$refs.ordersTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    if (this.configData.masterRolesId === '4') {
      this.fetchCountries();
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId, this.configData.stateId);
      this.fetchStores(this.selectedCountry, this.selectedState, null);
    } else if (this.configData.masterRolesId === '3') {
      this.fetchCountries();
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId, this.configData.stateId);
      this.selectedCity = this.configData.cityId;
      this.fetchStores(this.selectedCountry, this.selectedState, this.selectedCity);
    } else if (this.configData.masterRolesId === '1') {
      this.fetchCountries();
      this.selectedCountry = '1';
      this.fetchStates(this.selectedCountry);
    } else if (this.configData.masterRolesId === '2') {
      this.fetchStores(null, null, null);
    }
    this.fetchOrderPayments();
  },
};
</script>
